
import { useNavigate } from 'react-router-dom';
import '../css/acceso.css'
import { useEffect, useState } from 'react';

export default function Login() {
    const navigate = useNavigate();
    const [isSubmtting, setSubmit] = useState(null);
    const [recaptchaReady, setRecaptchaEstatus] = useState(false) 
    const [isLogged, setLogging] = useState(false)

    
    const RECAPTCHA_SITE = '6LfQftslAAAAALnZginGoE70ZFb1u_qvfLY4NhEj';

    const getToken = (e) => {
        setSubmit(true);  
        localStorage.removeItem('token')

        e.preventDefault();
        window.grecaptcha.ready(() => {      
            window.grecaptcha.execute(RECAPTCHA_SITE, { action: 'submit' }).then(token => {
                localStorage.setItem('token', true)
                console.log(localStorage)        
                setLogging(true);                    
            });
        });        
    }


    useEffect(() => {
        if (document !== null) {
            const loadScriptByURL = (id, url, callback) => {
                const isScriptExist = document.getElementById(id);
            
                if (!isScriptExist) {
                    var script = document.createElement("script");
                    script.type = "text/javascript";
                    script.src = url;
                    script.id = id;
                    script.onload = function () {
                        if (callback) callback();
                    };
                    document.body.appendChild(script);
                }
            
                if (isScriptExist && callback) callback();
            }
            
            // load the script by passing the URL
            loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE}`, function () {         
                setRecaptchaEstatus(true)
            });        
        }
    }, [])

    useEffect(() => {
        if (isLogged) {
            navigate('/dashboard')
        }
    }, [isLogged])

    return (
        <main id='mainLogin'>           
            <form className='form-signin w-100 m-auto' >
                <h3 className='mt-2 mb-4'>Consutorio Cuautitlán</h3>
                <h5 className="mb-3 fw-normal">Ingrese sus credenciales</h5>

                <div className="form-floating">
                    <input type="email" className="form-control" id="floatingInput" placeholder="Correo electrónico" required />
                    <label htmlFor="floatingInput">Correo electrónico</label>
                </div>
                <div className="form-floating">
                    <input type="password" className="form-control" id="floatingPassword" placeholder="Contraseña" required  />
                    <label htmlFor="floatingPassword">Contraseña</label>
                </div>
                {
                    !isSubmtting ?
                    <button className="w-100 btn btn-lg btn-primary" type="submit" onClick={getToken} disabled={!recaptchaReady}>
                        Iniciar sesión
                    </button>
                    :
                    <button className="w-100 btn btn-lg btn-primary" type="button" disabled>        
                        Cargando...&nbsp;
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                }
            </form>
        </main>
    )
}
