import '../css/home.css';
import { Link } from 'react-router-dom';

export default function Index(){
    return (
        <main className='d-flex h-100 text-center text-bg-dark' id='rootHome'>                
            <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
                <header className="mb-auto">
                    <div>
                        <h3 className="float-md-start mb-0">Bienvenido a tu consultorio!!</h3>
                        <nav className="nav nav-masthead justify-content-center float-md-end">
                            <Link to={'/acceso'} className='nav-link fw-bold py-1 px-0 text-white'>Acceso</Link>
                        </nav>
                    </div>
                </header>

                <section className="px-3">
                    <h1>Consultorio Cuautitlán</h1>
                    <p>
                        Hola <i>partner</i>, aquí todos nuestros queridos psicólogos son "<i>partner</i>"
                        <br/>
                        Todos ustedes nos ayudam a mejorar e innovar con su sitio favorito.
                    </p>
                    <p className='lead'>
                        Aquí podrás manejar, administrar y tener un mejor control de tus pacientes.
                    </p>
                    <p className="lead">
                        <Link to={'/acceso'} className='btn btn-lg btn-light fw-bold border-white bg-white'>Iniciar sesión</Link>
                    </p>                    
                </section>

                <footer className="mt-auto text-white-50">
                    Website build by <i>HPM&reg;</i>&copy; All Rights Reserved 
                </footer>
            </div>
        </main>
    )
}