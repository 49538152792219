

// Define a function to check if the user is authenticated
const isAuthenticated = () => {
    // Replace this with your own authentication logic
    const token = localStorage.getItem('token');

    return token !== null ? true:false;
  };



  export {isAuthenticated};