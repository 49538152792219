import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';

import { isAuthenticated } from './utils/methods'

import Home  from './routes/home';
import Login from './routes/login'
import ErrorBoundary from './routes/errorBoundary';
import PrivateLayout from './routes/privateLayout';
import Dashboard from './routes/private/dashboard';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.min.css';  



const checkPrivateRoutes = ({component}) => {
  return isAuthenticated() ? 
    <PrivateLayout>{component}</PrivateLayout>
    : <Navigate to='/acceso' replace={true} />;
}

// Create your browser router using `createBrowserRouter`
const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet/>,
    errorElement: <ErrorBoundary/>,
    children: [
      {
        path: '',
        element: <Home/>
      },
      {
        path: 'acceso',
        element: <Login/>
      },
      {
        path: 'dashboard',
        element: <PrivateLayout><Dashboard/></PrivateLayout>
      },
    ]
  } 
]);

const App = () => {
  return (
    <RouterProvider router={router}>
        <Outlet/>
    </RouterProvider>  
  );
}

export default App;
