import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../utils/methods";
import { useState } from "react";

function PrivateLayout({ children }) {
  const navigate = useNavigate();
  const [showContent, setContent] = useState(false)
  
  const salir = () => {
    localStorage.removeItem('token');
    navigate('/');
  }

  // LIMPIAR RECAPTCHA
  useEffect(() => {
    if (isAuthenticated() ) {
      setContent(true)
      if (document !== null) {
        if (document.getElementById('recaptcha-key') !== null){
          let a = document.getElementsByClassName('grecaptcha-badge')
          document.body.removeChild(document.getElementById('recaptcha-key'))
          // document.body.removeChild(a[0])
          for (let item of a){
            item.remove()
          }
        }
      }
    } else {
      navigate('/acceso')
    }    
  }, []);

  const content = <>
     <main className="container">
        {/* OFFCANVAS SIDEBAR */}
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">Consultas Cuautitlán</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="list-unstyled ps-0">
              <li className="mb-1">
                <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="false">
                  Home
                </button>
                <div className="collapse" id="home-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a href="#" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Overview</a></li>
                    <li><a href="#" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Updates</a></li>
                    <li><a href="#" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Reports</a></li>
                  </ul>
                </div>
              </li>
              <li className="mb-1">
                <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">
                  Dashboard
                </button>
                <div className="collapse" id="dashboard-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">                    
                    <li>
                      <Link replace={true} reloadDocument={true} to={'/dashboard'} className="link-body-emphasis d-inline-flex text-decoration-none rounded">Dashboard</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="mb-1">
                <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed" data-bs-toggle="collapse" data-bs-target="#orders-collapse" aria-expanded="false">
                  Orders
                </button>
                <div className="collapse" id="orders-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a href="#" className="link-body-emphasis d-inline-flex text-decoration-none rounded">New</a></li>
                    <li><a href="#" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Processed</a></li>
                    <li><a href="#" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Shipped</a></li>
                    <li><a href="#" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Returned</a></li>
                  </ul>
                </div>
              </li>
              <li className="border-top my-3"></li>
              <li className="mb-1">
                <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed" data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">
                  Cuenta
                </button>
                <div className="collapse" id="account-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a role="button" onClick={salir} className="link-dark d-inline-flex text-decoration-none rounded">Salir</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* FIN OFFCANVAS SIDEBAR */}

        <header className="fixed-top d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-1 border-bottom">
          <div className="col-8 col-md-4 col-lg-3 mb-0 px-2">
            <h4 className="m-0 d-flex w-100 align-items-center justify-content-center">Consultorio Cuautitlán</h4>
          </div>

          <div className="col-4 nav col-md-4 col-lg-4 col-md-auto justify-content-center mb-0">
            <a className="btn btn-outline-secondary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button">
              <i className="bi bi-list"></i>
            </a>           
          </div>

          <div className="col-md-3 text-end d-none d-md-block">
            <button type="button" className="btn btn-outline-primary me-2" onClick={salir}>Salir</button>
          </div>
        </header>

        {/* CHILDRENS */}
        <div className="container mt-5 pt-2">
         {children}
        </div>
      </main>
  </>

  return (
    <>
     {
      showContent ? content: null
     }
  
    </>
  )
} 
  

 
  

export default PrivateLayout;